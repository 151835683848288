.root {
    :global {
        .form-grid {
            @apply grid grid-cols-2 gap-4;
        }
        .form-group {
            @apply mb-6 md:mb-5;
        }
        label.heading {
            @apply block uppercase text-xs leading-[0.875rem] tracking-[0.03125rem] font-bold text-grey-1 mb-2 relative;
        }
        .asterisk {
            @apply mx-1  absolute;
        }
        .field-required {
            &:after {
                @apply content-['*'] ml-0.5 text-grey-2;
            }
        }
        input[type='text'],
        input[type='number'],
        input[type='tel'],
        input[type='email'],
        input[type='password'],
        select,
        .form-select,
        textarea {
            @apply appearance-none w-full border border-solid border-black bg-white text-black py-[0.6875rem] px-4 font-medium text-xs leading-[0.875rem] touch:text-base mb-0.5 rounded-none md:py-4 focus:shadow-none;

            + p.input-info {
                @apply text-grey-1 text-xs leading-[1.125rem] -tracking-[0.015625rem];
            }

            &::placeholder {
                @apply text-grey-1;
            }

            &:focus {
                @apply border-gold-1 outline-2 outline outline-gold-1;
                &::placeholder {
                    @apply text-grey-4;
                }
            }

            &.border-alert-danger {
                @apply border-red outline-none;
                &::placeholder {
                    @apply text-grey-2;
                }
                &:focus {
                    @apply border-red border outline-none;
                    &::placeholder {
                        @apply text-grey-2;
                    }
                }
            }

            &.disabled,
            &:disabled {
                @apply outline-none border-grey-9;
                &::placeholder {
                    @apply text-grey-9;
                }
                &:focus {
                    @apply border border-grey-9 outline-none;
                    &::placeholder {
                        @apply text-grey-9;
                    }
                }
            }
        }

        select,
        .form-select {
            background-image: url('data:image/svg+xml,<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.63681 8.41063L15.0699 2.18129C15.4212 1.81863 15.4212 1.26396 15.0699 0.922626C14.7186 0.581292 14.1258 0.581292 13.7745 0.922626L8.00008 6.53329L2.22563 0.922626C1.85238 0.581293 1.28152 0.581293 0.930221 0.922626C0.578924 1.26396 0.578924 1.81863 0.930221 2.18129L7.3414 8.41063C7.71465 8.75196 8.28551 8.75196 8.63681 8.41063Z" fill="black" stroke="black" stroke-width="0.5"/></svg>');
            background-position: 97% 50%;
            background-size: 1rem 0.625rem;
            @apply bg-no-repeat pr-9;
            &.disabled,
            &:disabled {
                background-image: url('data:image/svg+xml,<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.63681 8.41063L15.0699 2.18129C15.4212 1.81863 15.4212 1.26396 15.0699 0.922626C14.7186 0.581292 14.1258 0.581292 13.7745 0.922626L8.00008 6.53329L2.22563 0.922626C1.85238 0.581293 1.28152 0.581293 0.930221 0.922626C0.578924 1.26396 0.578924 1.81863 0.930221 2.18129L7.3414 8.41063C7.71465 8.75196 8.28551 8.75196 8.63681 8.41063Z" fill="rgb(130 130 130)" stroke="rgb(130 130 130)" stroke-width="0.5"/></svg>');
                @apply text-grey-9;
            }
        }

        .error {
            @apply text-grey-1 text-xs leading-[1.125rem] -tracking-[0.015625rem] inline-flex gap-2.5;
            &:before {
                background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" fill="%23E2204F" stroke="%23E2204F" stroke-width="0.7"/><path d="M6.31724 3.75816L6.42662 8.16962H7.57506L7.68444 3.75816H6.31724ZM7.00084 10.4483C7.41555 10.4483 7.74367 10.1338 7.74367 9.73277C7.74367 9.33173 7.41555 9.01727 7.00084 9.01727C6.58613 9.01727 6.258 9.33173 6.258 9.73277C6.258 10.1338 6.58613 10.4483 7.00084 10.4483Z" fill="white"/><path d="M6.3171 3.37637H5.92571L5.9354 3.76766L6.04478 8.17911L6.05401 8.55147H6.42648H7.57492H7.94739L7.95662 8.17911L8.06599 3.76766L8.07569 3.37637H7.6843H6.3171ZM7.0007 10.8301C7.6121 10.8301 8.12536 10.3586 8.12536 9.7328C8.12536 9.10697 7.6121 8.63549 7.0007 8.63549C6.3893 8.63549 5.87604 9.10697 5.87604 9.7328C5.87604 10.3586 6.3893 10.8301 7.0007 10.8301Z" fill="white" stroke="%23E2204F" stroke-width="0.7"/></svg>');
                @apply content-[''] bg-no-repeat bg-contain w-3 h-3 mt-[0.1875rem] min-w-[0.75rem];
            }
        }
        .form-checkbox {
            min-width: 1.25rem;
            @apply h-5 w-5 appearance-none align-middle border border-solid border-black bg-white rounded-none mr-2.5 mt-0.5;
            + label {
                @apply align-middle text-sm leading-6 text-primary;
            }

            &:checked {
                background-image: url('data:image/svg+xml,<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.5L2.33333 4.75L3.66667 6L9 1" stroke="%236D4B0F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                background-size: 0.625rem 0.4375rem;
                @apply bg-no-repeat bg-center;
            }

            &:disabled,
            &.disabled {
                @apply border-grey-9;

                + label {
                    @apply text-grey-9;
                }
            }
        }
        .form-radio {
            @apply select-none rounded-full appearance-none align-middle border border-solid border-black bg-white h-5 w-5 mr-2.5;

            + label,
            + span {
                @apply align-middle text-sm leading-6 text-primary;
            }

            &:checked {
                background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill="%236D4B0F"/></svg>');
                background-size: 0.875rem 0.875rem;
                @apply bg-no-repeat bg-center;
            }

            &:disabled,
            &.disabled {
                @apply border-grey-9;

                + label,
                + span {
                    @apply text-grey-9;
                }
            }
        }
        .checkbox-container {
            @apply flex;
        }
        .box-info {
            @apply mb-5;
            &-title {
                @apply font-normal text-xl;
            }
            .address-block {
                span {
                    @apply block text-p-1;
                }
            }
            .action-container {
                @apply pt-3 flex flex-wrap gap-2.5;
                a {
                    @apply relative pt-0 px-0 pb-[0.0625rem] no-underline uppercase font-bold text-sm leading-[1.125rem] tracking-[0.03125rem] text-blue-1 hover:text-hover focus:shadow-none;
                    &:after {
                        @apply content-[''] absolute bottom-0 inset-x-0 z-0 border-b border-solid border-current;
                    }
                }
            }
            .action-container.hidden {
                display: none;
            }
        }
        .form-grid-col {
            @apply w-full md:w-1/2 md:pr-5 mb-5;
            &:last-child {
                @apply md:pl-5 pr-0;
            }
            &-wrap {
                @apply pt-2 mb-4 md:flex my-2;
            }
        }
        [class*='AuthModal_signup-link'],
        [class*='AuthModal_login-link'],
        [class*='Auth_message-txt'],
        [class*='AuthModal_message-txt'] {
            @apply relative pt-0 px-0 pb-[0.0625rem] no-underline uppercase font-bold text-[0.75rem] leading-[1.125rem] tracking-[0.03125rem] text-blue-1 hover:text-hover focus:shadow-none hover:no-underline;
            &:after {
                @apply content-[''] absolute bottom-0 inset-x-0 z-0 border-b border-solid border-current;
            }
        }
        [class*='Auth_message-txt'] {
            @apply ml-0;
        }
        [class*='auth-modal_auth-input'] {
            @apply pt-[0.596rem] pb-[0.592rem] text-[0.875rem] leading-[1.813rem] tracking-[500];
        }
        [class*='AuthModal_sign-up-view'] {
            .form-group {
                @apply mb-5;
            }
        }
    }
}
